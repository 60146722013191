var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.loading && _vm.ready
        ? _c(
            "CRow",
            [
              _vm.errorMessage
                ? _c(
                    "CCol",
                    { attrs: { md: "12" } },
                    [
                      _c("CAlert", { attrs: { color: "warning" } }, [
                        _vm._v("Fehler: " + _vm._s(_vm.errorMessage))
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "CCol",
                { attrs: { md: "12" } },
                [
                  _c(
                    "CCard",
                    [
                      _c(
                        "CCardBody",
                        [
                          _c("h1", [
                            _vm._v(" Code: " + _vm._s(_vm.voucher.code) + " "),
                            _vm.voucher.discountType == "Amount"
                              ? _c("span", [
                                  _vm._v(
                                    " | Betrag: " +
                                      _vm._s(
                                        _vm.formatCurrency(_vm.voucher.amount)
                                      )
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("ValidatedCheckbox", {
                            attrs: {
                              translationKey: "voucher.isDeactivated",
                              field: _vm.$v.voucher.isDeactivated,
                              checked: _vm.$v.voucher.isDeactivated.$model
                            },
                            on: {
                              "update:checked": function($event) {
                                return _vm.$set(
                                  _vm.$v.voucher.isDeactivated,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          }),
                          _vm.voucher.discountType == "Percentage"
                            ? _c("ValidatedInput", {
                                attrs: {
                                  translationKey: "voucher.percentage",
                                  field: _vm.$v.voucher.percentage,
                                  value: _vm.$v.voucher.percentage.$model
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.voucher.percentage,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _c("ValidatedInput", {
                            attrs: {
                              translationKey: "voucher.minimalOrderAmount",
                              field: _vm.$v.voucher.minimalOrderAmount,
                              value: _vm.$v.voucher.minimalOrderAmount.$model,
                              type: "number"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.voucher.minimalOrderAmount,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          }),
                          _c("ValidatedInput", {
                            attrs: {
                              translationKey: "voucher.code",
                              field: _vm.$v.voucher.code,
                              value: _vm.$v.voucher.code.$model
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.voucher.code,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          }),
                          _c("ValidatedInput", {
                            attrs: {
                              translationKey: "voucher.validFrom",
                              field: _vm.$v.voucher.validFrom,
                              value: _vm.$v.voucher.validFrom.$model,
                              type: "date"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.voucher.validFrom,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          }),
                          _c("ValidatedCheckbox", {
                            attrs: {
                              translationKey: "voucher.noValidUntil",
                              field: _vm.$v.noValidUntil,
                              checked: _vm.$v.noValidUntil.$model
                            },
                            on: {
                              "update:checked": function($event) {
                                return _vm.$set(
                                  _vm.$v.noValidUntil,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          }),
                          !_vm.noValidUntil
                            ? _c("ValidatedInput", {
                                attrs: {
                                  translationKey: "voucher.validUntil",
                                  field: _vm.$v.voucher.validUntil,
                                  value: _vm.$v.voucher.validUntil.$model,
                                  type: "date"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.voucher.validUntil,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _c("ValidatedTextArea", {
                            attrs: {
                              translationKey: "voucher.note",
                              field: _vm.$v.voucher.note,
                              value: _vm.$v.voucher.note.$model
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.voucher.note,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          }),
                          _c("h2", [_vm._v("Transactions")]),
                          _c("DataTable", {
                            attrs: {
                              items: _vm.voucherTransactions.items,
                              fields: [
                                "amount",
                                "type",
                                "note",
                                "transactionAt",
                                "status",
                                {
                                  key: "orderStatus",
                                  label: "Status",
                                  _style: "width:30%",
                                  sorter: false,
                                  filter: false
                                }
                              ],
                              "table-filter-value": _vm.tableFilterValue,
                              "sorter-value": _vm.sorterValue,
                              loading: _vm.loading
                            },
                            on: {
                              "update:tableFilterValue": function($event) {
                                _vm.tableFilterValue = $event
                              },
                              "update:table-filter-value": function($event) {
                                _vm.tableFilterValue = $event
                              },
                              "update:sorterValue": function($event) {
                                _vm.sorterValue = $event
                              },
                              "update:sorter-value": function($event) {
                                _vm.sorterValue = $event
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "transactionAt",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        [
                                          _c("FormatedTimestamp", {
                                            attrs: {
                                              date: item.transactionAt,
                                              displayFormat: "P | p"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "status",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "enums.voucherTransactionStatus." +
                                                  item.status
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "orderStatus",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("td", [
                                        item.orderStatus
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "enums.orderStatus." +
                                                        item.orderStatus
                                                    )
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        !item.orderStatus
                                          ? _c("span", [_vm._v("N/A")])
                                          : _vm._e()
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "amount",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatCurrency(item.amount, {
                                                signDisplay: "always"
                                              })
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              936401458
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { staticClass: "ml-auto", attrs: { md: "12" } },
                    [
                      _c("CButton", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value: "forms.common.saveAndClose",
                            expression: "'forms.common.saveAndClose'"
                          }
                        ],
                        attrs: { color: "primary" },
                        on: { click: _vm.handleSubmit }
                      }),
                      _c("CButton", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value: "forms.common.cancel",
                            expression: "'forms.common.cancel'"
                          }
                        ],
                        attrs: { color: "secondary" },
                        on: { click: _vm.handleCancel }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }